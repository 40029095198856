import React from "react"

import style from "./Navbar.module.css"

class Navbar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isTop: true,
    }
  }

  componentDidMount() {
    document.addEventListener("scroll", e => {
      const isTop = window.scrollY < 100
      if (isTop !== this.state.isTop) this.setState({ isTop })
    })
  }

  render() {
    const { isTop } = this.state

    return (
      <nav
        className={`${style.container} ${
          isTop ? "" : style.darkBackground
        } navbar navbar-expand-md fixed-top`}
      >
        <div className="container">
          <a className="navbar-brand">
            <strong>Resultados Digitais</strong> Open Source
          </a>
          <button
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navcol-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse d-lg-flex" id="navcol-1">
            <ul className="nav navbar-nav ml-auto">
              <li className="nav-item" role="presentation">
                <a
                  href="https://github.com/ResultadosDigitais"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${style.link} nav-link`}
                >
                  Nossos <strong>Projetos</strong>
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  href="https://resultadosdigitais.com.br/trabalhe-conosco"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${style.link} nav-link`}
                >
                  Estamos <strong>Contratando</strong>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
