import React from "react"

import { feed } from "../../resources/medium"

import Section from "../Section"
import SectionHeader from "../Section/components/SectionHeader"
import SectionTitle from "../Section/components/SectionTitle"
import SectionSubTitle from "../Section/components/SectionSubTitle"
import Card from "../Card"
import CardImage from "../Card/components/CardImage"
import CardBody from "../Card/components/CardBody"
import CardTitle from "../Card/components/CardTitle"
import CardDescription from "../Card/components/CardDescription"
import ContainerLink from "../ContainerLink"

const MEDIUM_USERNAME = "rd-shipit"

const parseDescription = description => {
  const domSlice = new window.DOMParser().parseFromString(description, "text/html")
  if (domSlice == null) return ""

  const element = domSlice.querySelector("p:nth-child(3)")
  if (element == null) return ""

  return element.innerText || ""
}

const fetchMediumPosts = username => {
  return feed(username)
    .then(res => res.data)
    .catch(error => console.log(error))
}

class PostsSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      posts: [],
    }
  }

  componentDidMount() {
    fetchMediumPosts(MEDIUM_USERNAME)
      .then(res => res.items.slice(0, 2) || [])
      .then(items =>
        items.map(item => ({
          ...item,
          description: parseDescription(item.description),
        }))
      )
      .then(posts => this.setState({ posts }))
  }

  render() {
    const { posts } = this.state

    if (posts == null) return null

    return (
      <Section>
        <SectionHeader>
          <SectionTitle>Ship It!</SectionTitle>
          <SectionSubTitle>Visite nosso blog.</SectionSubTitle>
        </SectionHeader>
        <div className="row d-sm-flex align-items-sm-top">
          {posts.slice(0, 2).map(post => (
            <div key={post.id} className="col-12 col-sm-6">
              <ContainerLink
                href={post.link}
                alt={post.title}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Card>
                  <CardImage src={post.thumbnail} alt={post.title} />
                  <CardBody>
                    <CardTitle>{post.title}</CardTitle>
                    <CardDescription>
                      {post.description
                        .split(" ")
                        .slice(0, 30)
                        .join(" ")}
                      }...
                    </CardDescription>
                  </CardBody>
                </Card>
              </ContainerLink>
            </div>
          ))}
        </div>
      </Section>
    )
  }
}

export default PostsSection
