import React from "react"

import style from "./index.module.css"
import headerIllustration from "./header-illustration.svg"

const Header = () => (
  <section className={style.container}>
    <div className="container">
      <div className={`row d-sm-flex align-items-sm-center ${style.content}`}>
        <div className="col-12 col-sm-6 chamada">
          <h2 className={style.title}>
            Desenvolva, compartilhe e aproveite.
            <br /> Conheça os projetos Open Source da Resultados Digitais.
          </h2>
          <a
            href="https://github.com/ResultadosDigitais"
            target="_blank"
            rel="noopener noreferrer"
            className={`btn btn-primary ${style.projects_link}`}
          >
            Projetos
          </a>
        </div>
        <div className="col illustration">
          <img
            className="img-fluid"
            src={headerIllustration}
            alt="two devs illustration"
          />
        </div>
      </div>
    </div>
  </section>
)

export default Header
