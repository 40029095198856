import axios from "axios"

const baseURI = "https://api.github.com"

export const user = username => {
  return axios({
    method: "get",
    url: `${baseURI}/users/${username}`,
    responseType: "json",
  })
}

export const repositoriesByUser = username => {
  return axios({
    method: "get",
    url: `${baseURI}/users/${username}/repos?per_page=100&sort=pushed`,
    responseType: "json",
  })
}
