import React from "react"

import styles from "./WeAreHiringSection.module.css"
import resultadosDigitaisImage from "./resultados-digitais.jpg"
import Section from "../Section"

const WeAreHiringSection = () => (
  <Section className={styles.section}>
    <div className="row d-sm-flex align-items-sm-center">
      <div className="col-12 col-sm-6 headline">
        <header>
          <h1 className={styles.title}>
            Quer entrar no melhor lugar para se trabalhar em SC por 3 anos?
          </h1>
        </header>
        <p className={styles.description}>
          Infra estrutura de primeira, aprendizado e desenvolvimento acelerados
          e infinitas possibilidades de crescimento: o caminho perfeito para
          turbinar sua carreira.
        </p>
        <a
          href="https://resultadosdigitais.com.br/trabalhe-conosco"
          target="_blank"
          rel="noopener noreferrer"
          className={`btn btn-block ${styles.button}`}
        >
          <strong>Conheça nossas vagas</strong>
        </a>
      </div>
      <div className="col">
        <img
          className="img-fluid"
          src={resultadosDigitaisImage}
          alt="ResultadosDigitais headquarter photo"
        />
      </div>
    </div>
  </Section>
)

export default WeAreHiringSection
