import React from "react"

import ProjectCard from "../../../ProjectCard"

const featuredFilter = (repositories, featureds) => repositories

const FeaturedProjects = ({ repositories }) => (
  <section>
    <div className="container">
      <div className="row d-lg-flex align-items-lg-center">
        {featuredFilter(repositories).map(
          project => (
            <div key={`${project.id}-container`} className="col-12 col-sm-4">
              <ProjectCard key={project.id} project={project} />
            </div>
          )
        )}
      </div>
    </div>
  </section>
)

export default FeaturedProjects
