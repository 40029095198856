import React from "react"

import AllProjects from "./components/AllProjects"
import FeaturedProjects from "./components/FeaturedProjects"

import { repositoriesByUser } from "../../resources/github"

const GITHUB_USERNAME = 'ResultadosDigitais'

const featuredProjects = [
  'matrix',
  'rdstation-ruby-client',
  'feature_flagger',
  'upperkut',
  'espressione',
]

const fetchGithubRepos = (username) => {
  return repositoriesByUser(username)
    .then(res => res.data)
    .catch(error => {
      console.log(error)
    })
}

class ProjectsSection extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      repositories: []
    }
  }

  componentDidMount() {
    const repos = fetchGithubRepos(GITHUB_USERNAME)
      .then(repositories => repositories.filter(repo => repo.fork === false))
      .then(repositories => this.setState({ repositories }) )
  }

  render() {
    const { repositories } = this.state

    return (
      <>
        <FeaturedProjects
          repositories={repositories.filter(repo => featuredProjects.includes(repo.name))}
        />
        <AllProjects
          repositories={repositories.filter(repo => !featuredProjects.includes(repo.name))}
        />
      </>
    )
  }
}

export default ProjectsSection

