import React from "react"

import styles from "./CardTitle.module.css"

const CardTitle = ({ children }) => (
  <header>
    <h1 className={styles.title}>{children}</h1>
  </header>
)

export default CardTitle
