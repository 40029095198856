import axios from "axios"

const baseURI = "https://medium.com"

export const feed = username => {
  return axios({
    method: "get",
    url: `https://api.rss2json.com/v1/api.json?rss_url=${baseURI}/feed/${username}`,
    responseType: "json",
  })
}
