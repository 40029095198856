import React from "react"
import moment from "moment"

import style from "./index.module.css"
import ContainerLink from "../ContainerLink"

const ProjectCard = ({ project }) => {
  if (project == null) return null

  return (
    <ContainerLink href={project.html_url} alt="Alt text">
      <div className={`card ${style.container}`}>
        <div className="card-body">
          <h3 className={style.title}>{project.name}</h3>
          <div className={style.icons}>
            <span className={style.icon__item}>
              <i className="fas fa-code fa-fw" />&nbsp;{project.language}
            </span>
            <span className={style.icon__item}>
              <i className="fas fa-star fa-fw" />&nbsp;
              {project.stargazers_count}
            </span>
            <span className={style.icon__item}>
              <i className="far fa-eye fa-fw" />&nbsp;{project.watchers_count}
            </span>
            <span className={style.icon__item}>
              <i className="fas fa-edit fa-fw" />&nbsp;{moment(project.pushed_at).format('DD/MM/YYYY')}
            </span>
          </div>
          <p className={`card-text ${style.description}`}>
            {project.description}
          </p>
        </div>
      </div>
    </ContainerLink>
  )
}

export default ProjectCard
