import React from "react"

import ProjectCard from "../../../ProjectCard"

import style from "./index.module.css"

const AllProjects = ({ repositories }) => {
  return (
    <section className={style.container}>
      <div className="container">
        <h3 className={style.title}>Nossos Projetos</h3>
        <p className={style.subtitle}>Conheça todos os nossos projetos Open Source e fique a vontade para contribuir!</p>
        <div className="row d-sm-flex align-items-sm-center">
          {repositories.map(
            project => (
              <div key={`${project.id}-container`} className="col-12 col-sm-4">
                <ProjectCard key={project.id} project={project} />
              </div>
            )
          )}
        </div>
      </div>
    </section>
  )
}

export default AllProjects

