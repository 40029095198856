import React from "react"

import Layout from "../components/Layout"

import Header from "../components/Header"
import Navbar from "../components/Navbar"

import ProjectsSection from "../components/ProjectsSection"
import PostsSection from "../components/PostsSection"
import WeAreHiringSection from "../components/WeAreHiringSection"

const IndexPage = () => (
  <Layout>
    <Navbar />
    <Header />

    <ProjectsSection />
    <PostsSection />
    <WeAreHiringSection />
  </Layout>
)

export default IndexPage
