import React from "react"

import styles from "./Section.module.css"

const Section = ({ children, className }) => (
  <section className={`${styles.section} ${className}`}>
    <div className="container">{children}</div>
  </section>
)

export default Section
